import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const About = () => (
  <Wrapper>
    <h2>BRIDGE is a purpose-driven community, biased to action focused on the workplace, workforce, and marketplace.</h2>
    
    <p className="uk-text-lead">The BRIDGE mission is to create a cultural shift in companies where DEI principles flow through all facets of an organization, from the C-Suite and marketing through product development, procurement and customer service.</p>

    <p className="uk-text-lead">With the variety of programs that BRIDGE offers including proprietary <Link to="/research">research</Link>, <Link to="/storytelling-workshops">storytelling workshops</Link>, <Link to="/events">events</Link> and more, we identify, dismantle, and rethink the structures in place that currently contribute to the gap in belonging, representation, inclusion, diversity and equity (BRIDGE is an acronym for these).</p>

    <p className="uk-text-lead">Our long term goal, with the help of our founding board members composed of DEI and business leaders, is to create a comprehensive BRIDGE agenda for all companies and to subsequently certify against its implementation and measure its impact.</p>

    <CTA className="uk-button uk-button-primary uk-border-pill button" href="mailto:sheryl@wearebridge.com">Learn More &amp; Contact Us</CTA>
  </Wrapper>
)

const CTA = styled.a`
  &&& {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.purple};
  }
`;

const Wrapper = styled.div`
  /* padding: 50px 0; */
`

export default About