import React from "react"
import Layout from "../components/layout"
import SEO from "../components/atoms/SEO"
import valueframework from "../assets/images/bridge-value-framework-single.png"

import Section from "../components/molecules/Section"

import About from "../components/organisms/About"
import ColorStripe from "../components/atoms/ColorStripe"
import AdvisoryBoard from "../components/organisms/AdvisoryBoard"

import styled from 'styled-components';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[ `diversity`, `inclusion`, `DEI` ]} />
    <PaddedSection>
      <Image src={valueframework} alt="BRIDGE Value Framework"/>
      <About/>
    </PaddedSection>
    <ColorStripe/>
    {/* <AdvisoryBoard/> */}
  </Layout>
)

// const HorizontalRule = styled.hr`
//   margin: 0;
//   padding: 0;
//   height: 1px;
// `;

const Image = styled.img`
  width: auto;
  height: auto;
  width: clamp(320px, 63%, 620px);
  float: right;
  margin-bottom: 35px;
  @media screen and (min-width: 960px) {
    max-width: 75%;
    margin-left: 15px;
  }
`;

const PaddedSection = styled(Section)`
  @media screen and (min-width: 960px) {
    padding: 70px 0;
  }
`

export default IndexPage