import React from "react";

const Section = (props) => {

  const sectionClass = 'uk-section-primary' + 
    (typeof props.bg !== 'undefined' 
      ? ' bg-' + props.bg 
      : ''
    );

  const innerClass = 'uk-container uk-padding' + 
    (props.center === "true" 
      ? ' uk-text-center' 
      : ''
    ) + 
    (props.small === "true"
      ? ' uk-container-small'
      : ''
    );
    
  return (
    <>
      <section className={`${props.className} ${sectionClass}`}>
        <div className={innerClass}>
          {props.children}
        </div>
      </section>
    </>
  );
};

export default Section